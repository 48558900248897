.bg-img {
  background-image: url("../../assets/background3.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img-left {
  background-image: url("../../assets/background-img-ct1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
