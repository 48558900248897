@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;900&display=swap");

@font-face {
  font-family: "Mtavruli";
  src: url("./fonts/bpg_extrasquare_mtavruli_2009.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Arial";
  src: url("./fonts/bpg_arial_2009.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
